<template>
  <Popup ref="popupRef" title="设置岗位推荐" width="500px" @confirm="comfirm" @close="resetData" @opened="handleOpened">
    <div class="popup">
      <div v-for="(item, index) in form.postIds" :key="index">
        <div class="label">
          <span>职位排名{{index+1}}</span>
          <div class="flex">
            <el-button type="primary" size="mini" v-if="index == form.postIds.length - 1" @click="handleAdd">
              <div class="flex items-center">
                <img class="icon-add" src="@/assets/images/add-fill-w.png">
                <span>添加职位</span>
              </div>
            </el-button>
            <el-button icon="el-icon-delete" type="danger" size="mini" v-if="form.postIds.length > 1" @click="handleDetele(index)"></el-button>
          </div>
        </div>
        <el-input v-model="item.id" placeholder="请输入职位ID"></el-input>
      </div>
      <div class="label">配置锁定渠道</div>
      <!-- <el-cascader
        style="width: 100%"
        :value="form.lockChs"
        :options="lockChannelList"
        :props="{value: 'key', label: 'value', multiple: true}"
        clearable
        @change="cascaderChange($event, 'lock')">
      </el-cascader> -->
      <Cascader
        ref="lockRef"
        :value="form.lockChs"
        :options="lockChannelList"
        :props="{value: 'key', label: 'value', multiple: true}"
        @change="cascaderChange($event, 'lock')"
        placeholder="请选择锁定渠道">
      </Cascader>
      <div class="label">配置轮转渠道</div>
      <!-- <el-cascader
        style="width: 100%"
        :value="form.roundChs"
        :options="roundChannelList"
        :props="{value: 'key', label: 'value', multiple: true}"
        clearable
        @change="cascaderChange($event, 'round')">
      </el-cascader> -->
      <Cascader
        ref="roundRef"
        :value="form.roundChs"
        :options="roundChannelList"
        :props="{value: 'key', label: 'value', multiple: true}"
        @change="cascaderChange($event, 'round')"
        placeholder="请选择轮转渠道">
      </Cascader>
      <div class="label">轮转频率</div>
      <div class="flex items-center">
        <el-input class="mr16" placeholder="请输入" type="number" v-model="form.frequency" @change="frequencyInputChange"></el-input>
        <el-select
          v-model="unitType"
          placeholder="请选择">
          <el-option
            v-for="item in unitList"
            :key="item.type"
            :label="item.text"
            :value="item.type">
          </el-option>
        </el-select>
      </div>
      <div class="label">是否启用</div>
      <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { dictListApi } from '@/api/opera.js'
import { postRoundManageApi } from '@/api/admin.js'
import Cascader from '@/components/Cascader'
export default {
  components: {
    Popup,
    Cascader
  },
  data() {
    return {
      lockChannelList: [],
      roundChannelList: [],
      unitList: [
        {text: '小时/轮', type:0},
        {text: '天/轮', type:1},
      ],
      unitType: 0,
      form: {
        postIds: [
          {id: ''}
        ],
        lockChs: [],
        roundChs: [],
        frequency: '',
        status: 0
      },
      isEdit: false,
      composeType: '',
      uniqueId: ''
    }
  },
  mounted() {
    this.getChannelList()
  },
  methods: {
    open(option = {}) {
      console.log('option', option)
      this.isEdit = option.isEdit || false
      this.composeType = option.type || ''
      if(this.isEdit && option.detail) {
        this.uniqueId = option.detail.uniqueId
        this.form.postIds = option.detail.postIds.map(id => ({id}))
        this.form.lockChs = option.detail.lockChs.map(item => ([item.productId, item.ch]))
        this.form.roundChs = option.detail.roundChs.map(item => ([item.productId, item.ch]))
        this.form.status = option.detail.status
        if(option.detail.frequency) {
          if(option.detail.frequency % (24 * 60) == 0) {
            this.unitType = 1
            this.form.frequency = option.detail.frequency / 60 / 24
          } else {
            this.unitType = 0
            this.form.frequency = option.detail.frequency / 60
          }
        }
      }
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    handleOpened() {
      this.$refs.roundRef.initData()
      this.$refs.lockRef.initData()
    },
    resetData() {
      this.unitType = 0
      this.isEdit = false
      this.form = {
        postIds: [
          {id: ''}
        ],
        lockChs: [],
        roundChs: [],
        frequency: '',
        status: 0
      }
      this.uniqueId = ''
    },
    getChannelList() {
      const p1 = dictListApi({
        code: 'productId'
      })
      const p2 = dictListApi({
        code: 'channel'
      })
      Promise.all([p1, p2]).then(res => {
        const appList = res[0]?.data?.dataList[0]?.dictItem || []
        const channelList = res[1].data?.dataList[0]?.dictItem || []
        const list = appList.map(item => {
          return Object.assign({}, item, {
            children: channelList.map(ch => Object.assign({}, ch, {disabled: false})),
            disabled: false
          })
        })
        this.appList = appList
        this.channelList = channelList
        this.lockChannelList = list
        this.roundChannelList = JSON.parse(JSON.stringify(list))
        console.log('lockChannelList', list)
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    handleAdd() {
      this.form.postIds.push({id: ''})
    },
    handleDetele(index) {
      this.form.postIds.splice(index,1)
    },
    frequencyInputChange() {
      this.form.frequency = parseInt(this.form.frequency)
    },
    cascaderChange(e, type) {
      console.log('级联选择', e, type)
      let list = []
      if(type == 'round') {
        this.form.roundChs = e
        list = this.lockChannelList
      } else {
        this.form.lockChs = e
        list = this.roundChannelList
      }
      const obj = {}
      e.forEach(arr => {
        const appKey = arr[0]
        const chKey = arr[1]
        if(obj[appKey]) {
          obj[appKey].push(chKey)
        } else {
          obj[appKey] = [chKey]
        }
      })
      const keys = Object.keys(obj)
      for (const appItem of list) {
        if(appItem.children?.length > 0) {
          if(keys.includes(appItem.key)) {
            for (const chItem of appItem.children) {
              chItem.disabled = obj[appItem.key].includes(chItem.key)
            }
          } else {
            for (const chItem of appItem.children) {
              chItem.disabled = false
            }
          }
        }
        
      }
    },
    comfirm() {
      const idRegExp = /[0-9]+/
      for (const item of this.form.postIds) {
        if(!item.id) return this.$tips({message: '至少添加一个职位ID', type: 'warning'})
        if(!idRegExp.test(item.id)) return this.$tips({message: '请输入正确格式的职位ID', type: 'warning'})
      }
      if(!this.form.roundChs?.length && !this.form.lockChs?.length) return this.$tips({message: '请选择轮转/锁定渠道', type: 'warning'})
      if(this.form.roundChs?.length && !this.form.frequency) return this.$tips({message: '请输入轮转频率', type: 'warning'})

      const lockChs = []
      const roundChs = []
      let appKey = ''
      let appInfo = null
      for (const chs of this.form.lockChs) {
        if(appKey != chs[0]) {
          appKey = chs[0]
          appInfo = this.appList.find(item => appKey == item.key)
        }
        lockChs.push({
          ch: chs[1],
          productId: appKey,
          productName: appInfo.value
        })
      }

      for (const chs of this.form.roundChs) {
        if(appKey != chs[0]) {
          appKey = chs[0]
          appInfo = this.appList.find(item => appKey == item.key)
        }
        roundChs.push({
          ch: chs[1],
          productId: appKey,
          productName: appInfo.value
        })
      }

      const frequency = parseInt(this.form.frequency)
      const unitInfo = this.unitList.find(item => item.type == this.unitType)
      const formData = {
        composeType: this.composeType,
        act: this.isEdit ? 2 : 1,
        roundUnit: {
          frequency: this.unitType == 0 ? frequency * 60 : frequency * 24 * 60,
          frequencyDesc: this.form.frequency ? this.form.frequency + unitInfo.text : '',
          roundChs,
          lockChs,
          postIds: this.form.postIds.map(item => item.id),
          status: this.form.status
        }
      }
      if(this.isEdit) Object.assign(formData.roundUnit, {uniqueId: this.uniqueId})
      console.log('formData', formData)
      postRoundManageApi(formData).then(res => {
        console.log('设置结果', res)
        this.$tips({message: res.msg, type: 'success'})
        this.close()
        this.$emit('update')
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 8px;
  font-size: 14px;
  color: #505E5C;
}
.icon-add {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}
</style>